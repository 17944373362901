.login-screen {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89.5vh;
    background-color: #ffffff;
}
  
.login-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    padding: 2vw;
    max-height: 35vh;
    border: .5em outset rgb(94, 94, 94);
    border-radius: 5px;
    margin-bottom: 10vh;
}

  
.login-h1 {
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    }
  
.loginPageButtons {
    display: flex;
    justify-content: center;
    padding: 1em;
    gap: 1em;
}


.login-container{
    max-height: 75vh;
}

