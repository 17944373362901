.tippy-box[data-theme~='cedarF-theme'] {
    background-color: rgb(50, 50, 50);
    color: white;
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: rgb(255, 150, 150);
}

hr.popover-division {
    margin: .5rem;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: red;
    border-color: red;
    color: white;
}

.fc .fc-button-primary {
    background-color: white;
    border-color: red;
    color: red;
}

.fc .fc-button-primary:not(:disabled):hover {
    background-color: rgb(255, 150, 150);
    border-color: red;
    color: white;
}

.fc-highlight {
    color: red;
}